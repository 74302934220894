import { Anchor } from "@doar/components";

const Logo = () => {
    return (
        <Anchor path="/">
            <img
                style={{ height: "33px" }}
                src="/bestyLogo.png"
                alt="Besty logo"
            />
        </Anchor>
    );
};

export default Logo;
