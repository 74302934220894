export const isBffEnabled = (pms: string) => {
  const bffEnabled = ["track"];
  return bffEnabled.includes(pms);
};

import styled, { createGlobalStyle } from "@doar/shared/styled";
export const MobileAppStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overscroll-behavior: none; /* Prevents the page from “bouncing” or scrolling */
    overflow: hidden;
  }
`;