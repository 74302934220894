/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useCallback, useEffect } from "react";
import { Menu, X, ArrowLeft, PieChart, Mail, Home, MessageSquare, Settings, Terminal, TrendingUp } from "react-feather";
import { Navbar, Button, Text } from "@doar/components";
import Logo from "../../components/logo";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { toggleSidebar, toggleBody } from "src/redux/slices/ui";
import { getUnansweredCount } from "src/redux/slices/messages";
import { setSelected, getSelected } from "src/redux/slices/selected";
import { getUserInfo } from "src/redux/slices/global";
import { formatDistanceToNow, parseISO } from "date-fns";
import {
  StyledHeader,
  StyledLogo,
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyledNavbarHeader,
  StyledNavbarTitle,
  StyledMenuBtn,
  StyledSidebarBtn,
  StyledTrialCounter,
  StyledBookCall,
} from "./style";
import { IMenu } from "@doar/shared/types";
import { isBffEnabled } from "../../helpers/helpers";

import styled, { themeGet, device, css } from "@doar/shared/styled";

// TODO change to 800px
const StyledIframe = styled.iframe`
  margin-top: -5px;
  width: 100%;
  height: 100%;
  border: none;

  display: none;
  ${device.medium} {
    display: block;
  }
`;

const MobileOnlyWrapperDiv = styled.div`
  ${device.medium} {
    display: none;
  }
`;

const NavbarIframe = ({ userId, userToken, planText, selected }: { userId: string, userToken: string, planText: string, selected: string }) => {
  return <StyledIframe id="besty-navbar-iframe" src={`https://navbar-refresh-elanai.replit.app/?planText=${encodeURIComponent(planText)}&selected=${encodeURIComponent(selected)}`} />;
};

interface IProps {
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
  mobileHide?: boolean;
}


// note, to add menu items, add them here, AND in the replit! Here they're just used for the mobile version of the toolbar
const bffMenuData: IMenu[] = [
  {
    id: 1,
    label: "Analytics",
    url: "/upsell-analytics",
    Icon: PieChart,
  },
  {
    id: 2,
    label: "Messages",
    Icon: MessageSquare,
    url: "/message-dashboard",
  },
  {
    id: 3,
    label: "Listings",
    url: "/listings",
    Icon: Home,
    lite: false,
  },
  {
    id: 4,
    label: "Journeys",
    url: "/automated-messages",
    Icon: Terminal,
    lite: false,
  },
  {
    id: 5,
    label: "Broadcast",
    url: "/broadcast",
    Icon: Mail,
    lite: false,
  },
  {
    id: 6,
    label: "Settings",
    url: "/settings",
    Icon: Settings,
    lite: true,
  },
];


const nonBffMenuData: IMenu[] = [
  {
    id: 1,
    label: "Analytics",
    url: "/dashboard",
    Icon: PieChart,
    lite: true,
  },
  {
    id: 2,
    label: "Unanswered Messages",
    url: "/unanswered",
    Icon: Mail,
    lite: false,
  },
  // {
  //   id: 25,
  //   label: "Messages",
  //   url: "/message-dashboard",
  // },
  {
    id: 3,
    label: "Listings",
    url: "/listings",
    Icon: Home,
    lite: false,
  },
  {
    id: 7,
    label: "Review Responder",
    url: "/reviews",
    Icon: MessageSquare,
    lite: false,
  },
  {
    id: 8,
    label: "Tasks",
    url: "/tasks",
    Icon: MessageSquare,
    lite: false,
  },
  {
    id: 4,
    label: "Checkin/Checkout Adjustments",
    url: "/checkinCheckoutAdjustments",
    Icon: Settings,
    lite: true,
  },
  // {
  //   id: 26,
  //   label: "Automated Messages",
  //   url: "/automated-messages",
  //   Icon: Terminal,
  //   lite: false,
  // },
  {
    id: 10,
    label: "Broadcast",
    url: "/broadcast",
    Icon: Mail,
    lite: false,
  },
  // {
  //     id: 4,
  //     label: "Copilot",
  //     url: "/copilot",
  //     Icon: Compass,
  // },
  {
    id: 5,
    label: "Settings",
    url: "/settings",
    Icon: Settings,
    lite: true,
  },

  /*
  {
    id: 6,
    label: "Refer & Earn $100",
    url: "/refer",
    Icon: DollarSign,
    lite: false
  },
  */
  {
    id: 9,
    label: "Widget Messages",
    url: "/widgetMessages",
    Icon: Mail,
    lite: false,
  },
];


const Header = ({ hasSidebar, sidebarLayout, mobileHide }: IProps) => {
  const dispatch = useAppDispatch();
  const { sidebar, isBody } = useAppSelector((state) => state.ui);
  const messageCount = useAppSelector(getUnansweredCount);
  const { plan, trialEnd, userId, pms, userToken } = useAppSelector(getUserInfo);
  const selected = useAppSelector(getSelected);
  //console.log("selected: ", selected);
  useEffect(() => {
    const handleMessage = (event: any) => {
      // (1) Optional security check: if (event.origin !== "https://trusted.com") return;
      console.log("Iframe nav: Parent received message:", event.data);

      // data is in the format: "LINK: ___". visit the link:
      const link = (event.data + "").split(": ")[1];
      if (link) {
        console.log("Iframe nav: Visiting link: ", link);
        window.location.href = link;
      } else {
        console.error("Iframe nav: No link found in the message: ", event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // empty array ensures effect runs only on mount/unmount



  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarHandler = useCallback(
    (_: any, isOpen?: "open") => {
      dispatch(toggleSidebar({ isOpen }));
    },
    [dispatch]
  );

  const bodyHandler = useCallback(() => {
    dispatch(toggleBody());
    dispatch(toggleSidebar({ isOpen: "open" }));
  }, [dispatch]);

  const menuHandler = useCallback(() => {
    setMenuOpen((prev) => !prev);
    if (menuOpen) {
      sidebarHandler(undefined, "open");
    }
  }, [menuOpen, sidebarHandler]);

  useEffect(() => {
    return () => {
      sidebarHandler(undefined, "open");
      bodyHandler();
    };
  }, [sidebarHandler, bodyHandler]);

  let planText = "";

  if (plan === "free" || plan === "free_forever") {
    planText = "Free plan";
  } else if (plan == "trial") { 
    planText = "Setup mode"
  } else if (plan === "trial_with_card") {
    if (trialEnd) {
      // Parse the trial end date and compute the relative time from now.
      const trialEndDate = parseISO(trialEnd);
      let distance = formatDistanceToNow(trialEndDate, { addSuffix: true });
      // Remove the word "about " from the formatted distance, if present.
      distance = distance.replace("about ", "");
      planText = `Free trial ends ${distance}`;
    } else {
      planText = "Free trial";
    }
  } else if (plan === "paid") {
    planText = "Paid plan";
  } else {
    planText = "";
  }


    const bffMode = <>
    </>;


  // note for now, StyledHeader is display:none for mobile view
  return (
    <>
      <StyledHeader mobileHide={mobileHide}>
        {hasSidebar && sidebarLayout === 1 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="menu-btn"
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn
              variant="texted"
              onClick={!isBody ? sidebarHandler : bodyHandler}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="sidebar-btn"
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {hasSidebar && sidebarLayout === 2 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={!sidebar}
              $bodyOpen={false}
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn variant="texted" onClick={sidebarHandler} $sidebarOpen={!sidebar} $bodyOpen={false}>
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {!hasSidebar && (
          <StyledMenuBtn variant="texted" onClick={menuHandler} $hasSidebar={hasSidebar} $sidebarOpen={!sidebar}>
            <Menu size={20} strokeWidth="2.5px" />
          </StyledMenuBtn>
        )}


        { isBffEnabled(pms) ? (<>

            <NavbarIframe
            userId={userId}
            userToken={userToken}
            planText={planText}
            selected={selected}
          />

          <MobileOnlyWrapperDiv>
              <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler} className="navbar-wrap">
                <StyledNavbarMenu $isOpen={menuOpen} onClick={(e) => e.stopPropagation()}>
                  <StyledNavbarHeader>
                    <Logo />
                    <Button variant="texted" onClick={menuHandler}>
                      <X color="#7987a1" width={20} strokeWidth="2.5px" />
                    </Button>
                  </StyledNavbarHeader>
                  <StyledNavbarTitle></StyledNavbarTitle>
                    <Navbar
                      userId={userId}
                      menus={bffMenuData}
                      messageCount={messageCount}
                      pms={pms as "hostaway" | "guesty"}
                    />
                </StyledNavbarMenu>
              </StyledNavbarWrap>
              </MobileOnlyWrapperDiv>
            </>

        ) : (
              <>
                <StyledLogo>
                  <Logo />
                </StyledLogo>

            <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler} className="navbar-wrap">
              <StyledNavbarMenu $isOpen={menuOpen} onClick={(e) => e.stopPropagation()}>
                <StyledNavbarHeader>
                  <Logo />
                  <Button variant="texted" onClick={menuHandler}>
                    <X color="#7987a1" width={20} strokeWidth="2.5px" />
                  </Button>
                </StyledNavbarHeader>
                <StyledNavbarTitle></StyledNavbarTitle>
                  <Navbar
                    userId={userId}
                    menus={nonBffMenuData}
                    messageCount={messageCount}
                    pms={pms as "hostaway" | "guesty"}
                  />
              </StyledNavbarMenu>
            </StyledNavbarWrap>

            <StyledTrialCounter>
              {planText}
            </StyledTrialCounter>
            <StyledBookCall>
              <a onClick={() => window.open("https://master--bestyai.netlify.app/demo-booking", "_blank")}>Call us</a>
            </StyledBookCall>
          </>

        )}

      </StyledHeader>
    </>
  );
};

Header.defaultProps = {
  sidebarLayout: 1,
};

export default Header;
