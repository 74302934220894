import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import analyticsSlice from "./slices/analytics";
import attributionsSlice from "./slices/attributions";
import autopilotSettingsSlice from "./slices/autopilotSettings";
import copilotSlice from "./slices/copilot";
import earlyCheckinUpsellsSlice from "./slices/earlyCheckinUpsells";
import globalSlice from "./slices/global";
import hostNotesSlice from "./slices/hostNotes";
import inquiryWinbackSettingsSlice from "./slices/inquiryWinbackSettings";
import listingSlice from "./slices/listings";
import listingSetsSettingsSlice from "./slices/listingSetsSettings";
import messagesSlice from "./slices/messages";
import orphanNightsSettingsSlice from "./slices/orphanNightsSettings";
import settingsSlice from "./slices/settings";
import tasksSlice from "./slices/tasks";
import themeReducer from "./slices/theme";
import timeUpdatesSlice from "./slices/timeUpdatesSlice";
import uiReducer from "./slices/ui";
import widgetMessagesSlice from "./slices/widgetMessages";
import widgetSettingsSlice from "./slices/widgetSettings";
import reviewRequestSettingsSlice from "./slices/reviewRequestSettings";
import journeySlice from "./slices/journeySlice";
import selectedReducer from "./slices/selected";

const persistConfig = {
  key: "doar",
  version: 1.1,
  blacklist: [
    "ui",
    "messages",
    "widgetMessages",
    "attributions",
    "global",
    "listings",
    "analytics",
    "settings",
    "autopilotSettings",
    "widgetSettings",
    "orphanNightsSettings",
    "hostNotes",
    "tasks",
    "inquiryWinbackSettings",
    "copilot",
  ],
  storage,
};

export const rootReducer = combineReducers({
  ui: uiReducer,
  theme: themeReducer,
  messages: messagesSlice,
  widgetMessages: widgetMessagesSlice,
  attributions: attributionsSlice,
  global: globalSlice,
  listings: listingSlice,
  analytics: analyticsSlice,
  settings: settingsSlice,
  widgetSettings: widgetSettingsSlice,
  autopilotSettings: autopilotSettingsSlice,
  orphanNightsSettings: orphanNightsSettingsSlice,
  hostNotes: hostNotesSlice,
  earlyCheckinUpsells: earlyCheckinUpsellsSlice,
  tasks: tasksSlice,
  inquiryWinbackSettings: inquiryWinbackSettingsSlice,
  copilot: copilotSlice,
  reviewRequestSettings: reviewRequestSettingsSlice,
  listingSetsSettings: listingSetsSettingsSlice,
  timeUpdatesData: timeUpdatesSlice,
  journeys: journeySlice,
  selected: selectedReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
