import Header from "./header";
import Footer from "./footer";
import Warning from "src/components/bestyLiteWarning";
import styled from "@doar/shared/styled";

const StyledWrapper = styled.div`
    width: 98%;
    max-width: 2000px;
    margin: 0 auto;
`;

interface IProps {
    children: React.ReactNode;
    hasSidebar?: boolean;
    hideFooter?: boolean;
    sidebarLayout?: 1 | 2;
    mobileHide?: boolean;
    layout?: "app";
}

const Layout = ({
    children,
    hasSidebar,
    hideFooter,
    sidebarLayout,
    mobileHide
}: IProps) => {

    return (
        <>
            <Header hasSidebar={hasSidebar} sidebarLayout={sidebarLayout} mobileHide={mobileHide} />
            <Warning />
            <StyledWrapper>
                {children}
            </StyledWrapper>
            {!hideFooter && <Footer />}
        </>
    );
};

Layout.defaultProps = {
    hideFooter: false,
};

export default Layout;