import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

// which page is currently selected?

export interface SelectedState {
    selected: string;
}

const initialState: SelectedState = {
    selected: ""
};

const selectedSlice = createSlice({
    name: "selected",
    initialState,
    reducers: {
        setSelected: (state, action) => {
            //console.log("setting selected: ", action.payload);
            state.selected = action.payload;
        }
    },
});

export const { setSelected } = selectedSlice.actions;

export const getSelected = (state: RootState) => state.selected.selected;

export default selectedSlice.reducer;
