import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ReviewRequestSettingsState {
  enabled: boolean;
  hours_before_departure: number;
  is_before: boolean;
  template: string;
  hostaway_listing_id: string | null;
  fetched: boolean;
}

const initialState: ReviewRequestSettingsState = {
  enabled: false,
  hours_before_departure: 12,
  is_before: true,
  template: "We hope you enjoyed your stay! Please leave us a review if you can.",
  hostaway_listing_id: null,
  fetched: false,
};

export const reviewRequestSettingsSlice = createSlice({
  name: "reviewRequestSettings",
  initialState,
  reducers: {
    setReviewRequestSettings: (state, action) => {
      return {
        ...action.payload,
        fetched: true,
      };
    },
    clearReviewRequestSettings: () => initialState,
  },
});

export const { setReviewRequestSettings, clearReviewRequestSettings } = reviewRequestSettingsSlice.actions;

export const getReviewRequestSettings = (state: RootState) => state.reviewRequestSettings;

export default reviewRequestSettingsSlice.reducer;
