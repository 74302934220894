import styled, {
    device,
    space,
    border as borderStyle,
    SpaceProps,
    BorderProps,
    css,
    themeGet,
} from "@doar/shared/styled";

interface IProps extends SpaceProps, BorderProps {
    $fullHeight?: boolean;
    $align: "top" | "center" | "bottom";
}

    // background-color: ${themeGet("colors.lilac")};
const contentCSS = css<IProps>`
    background-color: white;

    padding: 20px;
    ${device.mobile} {
        padding: 0px;
    }
    ${device.large} {
        padding: 25px;
    }
    ${device.xlarge} {
        padding: 30px;
    }

    ${device.nonMobile} {
        ${({ $fullHeight }) =>
            $fullHeight &&
            css`
                min-height: calc(100vh - 65px);
                display: flex;
                flex-direction: column;
                justify-content: start;
            `}
    }

    ${device.mobile} {
        ${({ $fullHeight }) =>
            $fullHeight &&
            css`
                display: flex;
                flex-direction: column;
                justify-content: start;
            `}
    }

    ${({ $align }) =>
        $align &&
        $align !== "center" &&
        css`
            & > .container {
                flex: 1;
            }
        `}
	${({ borderBottom, borderBottomWidth }) =>
        (borderBottom || borderBottomWidth) &&
        css`
            border-bottom-style: solid;
            border-bottom-color: white;
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray500")};
        `}
    ${space};
    ${borderStyle};
`;

export const StyledContent = styled(
    ({
        p,
        px,
        py,
        m,
        mx,
        my,
        mt,
        borderBottomWidth,
        $fullHeight,
        $align,
        ...rest
    }) => <div {...rest} />
)<IProps>`
    ${contentCSS}
`;
