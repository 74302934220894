import {
  AskCopilotRequest,
  AskCopilotResponse,
  AskQuestionRequest,
  AskQuestionResponse,
  BtTemplateVarResponseType,
  BtTemplateVarType,
  CopilotThread,
  CreateCopilotThreadRequest,
  DeleteCopilotThreadRequest,
  DisableListingsRequest,
  DownloadTasksRequest,
  DownloadTasksResponse,
  EnableListingsRequest,
  FetchPrefDetailsRequest,
  FetchPrefDetailsResponse,
  FetchUsersRequest,
  FetchUsersResponse,
  GetAgentStatsRequest,
  GetAgentStatsResponse,
  GetAnalyticsRequest,
  GetAnalyticsResponse,
  GetAttributionsResponse,
  GetAttributionTableResponse,
  GetAutopilotSettingsRequest,
  GetAutopilotSettingsResponse,
  GetBillingSettingsRequest,
  GetBillingSettingsResponse,
  GetCopilotThreadsRequest,
  GetCopilotThreadsResponse,
  GetEarlyCheckinUpsellsRequest,
  GetEarlyCheckinUpsellsResponse,
  GetEciLcoSettingsRequest,
  GetEciLcoSettingsResponse,
  GetHostNotesRequest,
  GetHostNotesResponse,
  GetInquiryWinbackSettingsRequest,
  GetInquiryWinbackSettingsResponse,
  GetListingDataPointsRequest,
  GetListingDataPointsResponse,
  GetListingIdMatchesRequest,
  GetListingIdMatchesResponse,
  GetListingSetsSettingsRequest,
  GetListingSetsSettingsResponse,
  GetListingsRequest,
  GetListingsResponse,
  GetOrphanNightsSettingsRequest,
  GetOrphanNightsSettingsResponse,
  GetPreviousBroadcastsRequest,
  GetPreviousBroadcastsResponse,
  GetQuoteRequest,
  GetQuoteResponse,
  GetReservationsForBroadcastRequest,
  GetReservationsForBroadcastResponse,
  GetReviewRequestSettingsRequest,
  GetReviewRequestSettingsResponse,
  GetReviewResponderSettingsRequest,
  GetReviewResponderSettingsResponse,
  GetReviewsRequest,
  GetSentimentsRequest,
  GetSentimentsResponse,
  GetSettingsRequest,
  GetSettingsResponse,
  GetTasksRequest,
  GetTasksResponse,
  GetThreadRequest,
  GetThreadResponse,
  GetThreadsRequest,
  GetThreadsResponse,
  GetUpgradeQuoteRequest,
  GetUserInfoRequest,
  GetUserInfoResponse,
  GetWidgetSettingsRequest,
  GetWidgetSettingsResponse,
  ImportGuestyTagsRequest,
  ImportGuestyTagsResponse,
  LoginInfo,
  LoginResponse,
  MessageJourney,
  RejectAttributionRequest,
  RemoveThreadRequest,
  RetargetingSettings,
  SendBroadcastRequest,
  SendBroadcastResponse,
  SendMessageRequest,
  SendReviewReplyRequest,
  SettleConfirmationRequest,
  SignupInfo,
  TemplateVariable,
  TimeUpdatesType,
  UpdateAutopilotSettingsRequest,
  UpdateAutopilotSettingsResponse,
  UpdateBillingSettingsRequest,
  UpdateBillingSettingsResponse,
  UpdateEarlyCheckinUpsellsRequest,
  UpdateEarlyCheckinUpsellsResponse,
  UpdateEciLcoSettingsRequest,
  UpdateEciLcoSettingsResponse,
  UpdateGeneralSettingsRequest,
  UpdateHostNotesRequest,
  UpdateHostNotesResponse,
  UpdateInquiryWinbackSettingsRequest,
  UpdateInquiryWinbackSettingsResponse,
  UpdateListingSetsSettingsRequest,
  UpdateListingSetsSettingsResponse,
  UpdateOrphanNightsSettingsRequest,
  UpdateOrphanNightsSettingsResponse,
  UpdateReviewRequestSettingsRequest,
  UpdateSettingsRequest,
  UpdateSettingsResponse,
  UpdateStripePlanRequest,
  UpdateStripePlanResponse,
  UpdateTaskRequest,
  UpdateTaskResponse,
  UpdateTasksRequest,
  UpdateTasksResponse,
  UpdateWidgetSettingsRequest,
  UpdateWidgetSettingsResponse,
} from "./api-client-types";

import { v4 as uuidv4 } from "uuid";

import { client } from "./constants";

export const getDashboardState = () => {
  const resp = {
    isEnabled: true,
  };
  return resp;
};

export const getThread = async (req: GetThreadRequest): Promise<GetThreadResponse> => {
  try {
    const response = await client.get(`/getThread`, {
      params: {
        userToken: req.userToken,
        hostaway_conversation_id: req.hostaway_conversation_id,
      },
    });
    return { error: false, thread: response.data };
  } catch (error) {
    return { error: true, thread: null };
  }
};

export const setShownFirstMessagePopup = async (req: { userToken: string }): Promise<void> => {
  return new Promise((resolve, _) => {
    client.post(`/setShownFirstMessagePopup`, { userToken: req.userToken }).then(() => {
      resolve();
    });
  });
};

export const sendMessage = async (req: SendMessageRequest): Promise<void> => {
  return new Promise((resolve, _) => {
    client
      .post(`/sendMessage`, { conversationId: req.conversationId, text: req.text, userToken: req.userToken })
      .then(() => {
        resolve();
      });
  });
};

export const getListingIdMatches = async (req: GetListingIdMatchesRequest): Promise<GetListingIdMatchesResponse> => {
  return client.get(`/getListingIdMatches`, { params: { userToken: req.userToken, listingId: req.listingId } });
};

export const fetchPrefDetails = async (req: FetchPrefDetailsRequest): Promise<FetchPrefDetailsResponse> => {
  return client.get(`/fetchPrefDetails`, { params: { userToken: req.userToken, prefId: req.prefId } });
};

export const getThreads = async (req: GetThreadsRequest): Promise<GetThreadsResponse> => {
  try {
    const response = await client.get(`/unansweredMessages`, {
      params: {
        userToken: req.userToken,
        page: req.page,
        paginationLimit: req.paginationLimit,
        offset: req.offset,
        messagesToFetch: req.messagesToFetch,
        all: req.all,
        listingFilter: req.listingFilter,
      },
    });
    return { error: false, count: response.data.count, threads: response.data.threads };
  } catch (error) {
    return { error: true, count: 0, threads: null };
  }
};

export const archiveThread = async (req: RemoveThreadRequest): Promise<void> => {
  return new Promise((resolve, _) => {
    client
      .delete(`/unansweredMessages`, { params: { userToken: req.userToken, threadId: req.threadId } })
      .then((res) => {
        resolve();
      });
  });
};

export const archiveAllThreads = async (req: { userToken: string }): Promise<void> => {
  return new Promise((resolve, _) => {
    client.delete(`/unansweredMessages`, { params: { userToken: req.userToken, threadId: "all" } }).then((res) => {
      resolve();
    });
  });
};

export const authUserToken = async (req: {
  userToken: string;
}): Promise<{ loginStatus: number; userId: number } | null> => {
  return new Promise((resolve, _) => {
    client
      .get(`/authUserToken`, { params: { userToken: req.userToken } })
      .then((res) => {
        resolve({ loginStatus: res.data.loginStatus, userId: res.data.userId });
      })
      .catch(() => {
        resolve(null);
      });
  });
};

export const getWebAccessTokensInitialized = async (userId: string): Promise<{ initialized: boolean } | null> => {
  return new Promise((resolve, _) => {
    console.log(userId);
    client
      .get(`/getWebAccessTokensInitialized`, { params: { userToken: userId } })
      .then((res) => {
        resolve({ initialized: res.data.initialized });
      })
      .catch(() => {
        resolve(null);
      });
  });
};

export const getUserHasInvitedUs = async (userId: string): Promise<{ hasInvitedUs: boolean } | null> => {
  return new Promise((resolve, _) => {
    client
      .get(`/getUserHasInvitedUs`, { params: { userToken: userId } })
      .then((res) => {
        resolve({ hasInvitedUs: res.data.hasInvitedUs });
      })
      .catch(() => {
        resolve(null);
      });
  });
};

export const updateUserHasInvitedUs = async (
  userId: string,
  hasInvitedUs: boolean
): Promise<{ status: number } | null> => {
  return new Promise((resolve, _) => {
    client
      .post(`/updateUserHasInvitedUs`, { userToken: userId, hasInvitedUs: hasInvitedUs })
      .then((res) => {
        resolve({ status: res.status });
      })
      .catch(() => {
        resolve(null);
      });
  });
};

// export const authUserId = async (
//     req: {userToken: string}
// ): Promise<object> => {
//     return new Promise((resolve, _) => {
//         client.post(`/authUserId`, {  userToken: req.userToken }).then((res) => {
//             resolve(res.data);
//         }).catch((err) => {
//             resolve({loginStatus: 3});
//         });
//     });
// }

export const getUserInfo = async (req: GetUserInfoRequest): Promise<GetUserInfoResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/userInfo`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const getListings = async (req: GetListingsRequest): Promise<GetListingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/listings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const enableListings = async (req: EnableListingsRequest): Promise<void> => {
  return new Promise((resolve, _) => {
    client.post(`/toggleListings`, { userToken: req.userToken, listingIds: req.listingIds, enabled: true }).then(() => {
      resolve();
    });
  });
};

export const disableListings = async (req: DisableListingsRequest): Promise<void> => {
  return new Promise((resolve, _) => {
    client
      .post(`/toggleListings`, { userToken: req.userToken, listingIds: req.listingIds, enabled: false })
      .then(() => {
        resolve();
      });
  });
};

export const getSentiments = async (req: GetSentimentsRequest): Promise<GetSentimentsResponse> => {
  return client.get(`/sentiments`, { params: req }).then((res) => res.data);
};

export const downloadTasks = async (req: DownloadTasksRequest): Promise<DownloadTasksResponse> => {
  return client.get(`/downloadTasks`, { params: req }).then((res) => res.data);
};

export const askQuestion = async (req: AskQuestionRequest): Promise<AskQuestionResponse> => {
  return new Promise((resolve, _) => {
    client
      .post(`/sendSandboxMessage`, req)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve({
          message: {
            id: uuidv4(),
            text: "Sorry, we couldn't process your message. Please try again later.",
            sender: "besty",
            escalated: true,
          },
        });
      });
  });
};

export const getAnalytics = async (req: GetAnalyticsRequest): Promise<GetAnalyticsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/dashboard`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};
export const getWidgetSettings = async (req: GetWidgetSettingsRequest): Promise<GetWidgetSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/widgetSettings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};
export const updateWidgetSettings = async (req: UpdateWidgetSettingsRequest): Promise<UpdateWidgetSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/widgetSettings`, req);
    resolve({});
  });
};
export const getAutopilotSettings = async (req: GetAutopilotSettingsRequest): Promise<GetAutopilotSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/autopilotSettings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const getListingDataPoints = async (req: GetListingDataPointsRequest): Promise<GetListingDataPointsResponse> => {
  return new Promise((resolve, _) => {
    client
      .get(`/getListingDataPoints`, { params: { listingId: req.listingId, userToken: req.userToken } })
      .then((res) => {
        resolve(res.data);
      });
  });
};

export const updateAutopilotSettings = async (
  req: UpdateAutopilotSettingsRequest
): Promise<UpdateAutopilotSettingsResponse> => {
  return client.post(`/autopilotSettings`, req);
};

export const updateEcilcoSettings = async (req: UpdateEciLcoSettingsRequest): Promise<UpdateEciLcoSettingsResponse> => {
  return client.post(`/ecilcoSettings`, req);
};

export const getEcilcoSettings = async (req: GetEciLcoSettingsRequest): Promise<GetEciLcoSettingsResponse> => {
  return client.get(`/ecilcoSettings`, { params: { userToken: req.userToken } });
};

export const getOrphanNightsSettings = async (
  req: GetOrphanNightsSettingsRequest
): Promise<GetOrphanNightsSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/orphanSettings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const updateOrphanNightsSettings = async (
  req: UpdateOrphanNightsSettingsRequest
): Promise<UpdateOrphanNightsSettingsResponse> => {
  return client.post(`/orphanSettings`, req);
};

// ----- LISTING SETS -----
export const getListingSetsSettings = async (
  req: GetListingSetsSettingsRequest
): Promise<GetListingSetsSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/listingSetsSettings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const updateListingSetsSettings = async (
  req: UpdateListingSetsSettingsRequest
): Promise<UpdateListingSetsSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/listingSetsSettings`, req);
    resolve({});
  });
};

export const importGuestyTags = async (req: ImportGuestyTagsRequest): Promise<ImportGuestyTagsResponse> => {
  return client.get(`/importGuestyTags`, { params: { userToken: req.userToken } });
};

// ----- BROADCAST -----
export const insertBroadcastJob = async (req: SendBroadcastRequest): Promise<SendBroadcastResponse> => {
  return new Promise((resolve, reject) => {
    client
      .post(`/insertBroadcastJob`, req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPreviousBroadcasts = async (
  req: GetPreviousBroadcastsRequest
): Promise<GetPreviousBroadcastsResponse> => {
  return new Promise((resolve, reject) => {
    client
      .get(`/getPreviousBroadcasts`, { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReservationsForBroadcast = async (
  req: GetReservationsForBroadcastRequest
): Promise<GetReservationsForBroadcastResponse> => {
  return new Promise((resolve, reject) => {
    client
      .get(`/getReservationsForBroadcast`, { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSettings = async (req: UpdateSettingsRequest): Promise<UpdateSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/settings`, req);
    resolve({});
  });
};

export const updateGeneralSettings = async (req: UpdateGeneralSettingsRequest): Promise<UpdateSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/updateGeneralSettings`, req);
    resolve({});
  });
};

export const getSettings = async (req: GetSettingsRequest): Promise<GetSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/settings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const getUpgradeQuote = async (req: GetUpgradeQuoteRequest): Promise<GetQuoteResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/getUpgradeQuote`, { params: req, headers: { "Cache-Control": "no-cache" } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const getQuote = async (req: GetQuoteRequest): Promise<GetQuoteResponse> => {
  return new Promise((resolve, _) => {
    client
      .get(`/getQuote`, { params: { userToken: req.userToken, quantity: req.quantity, period: req.period } })
      .then((res) => {
        resolve(res.data);
      });
  });
};

export const loginUser = async (req: LoginInfo): Promise<LoginResponse> => {
  return new Promise((resolve, _) => {
    client
      .post(`/loginUser`, { email: req.email, password: req.password })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve({ loginStatus: 3, userToken: "" });
      });
  });
};

export const sendResetEmail = async (req: { email: string }): Promise<void> => {
  return new Promise((resolve, _) => {
    client
      .post(`/sendResetEmail`, { email: req.email })
      .then((res) => {
        resolve();
      })
      .catch(() => {
        resolve();
      });
  });
};

export const resetPassword = async (req: { password: string; besty_token: string }): Promise<object> => {
  return new Promise((resolve, _) => {
    client
      .post(`/resetPassword`, { password: req.password, besty_token: req.besty_token })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err.data);
      });
  });
};

export const signupUser = async (req: SignupInfo): Promise<object> => {
  return new Promise((resolve, __) => {
    client
      .post("/signupUser", { ...req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err.response.data);
      });
  });
};

export const addPmsInfo = async (req: {
  userToken: string;
  pms: string;
  pmsSecret: string;
  pmsId: string;
  pmsKey: string;
}): Promise<void> => {
  return new Promise((resolve, __) => {
    client
      .post("/addPMS", { ...req })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve();
      });
  });
};

export const addEscapiaPMS = async (req: { userToken: string; pms: string; newState: string }): Promise<void> => {
  return new Promise((resolve, __) => {
    client
      .post("/addEscapiaPMS", { ...req })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve();
      });
  });
};

export const getReferrals = async (req: { userToken: string }): Promise<object> => {
  return new Promise((resolve, __) => {
    client
      .post("/getReferrals", { ...req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
};

export const savePaypal = async (req: { email: string; userToken: string }): Promise<void> => {
  return new Promise((resolve, __) => {
    client
      .post("/savePaypal", { ...req })
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        resolve();
      });
  });
};

export const getCheckoutSession = async (req: { userToken: string; referral: string }): Promise<object> => {
  return new Promise((resolve, __) => {
    client
      .post("/checkout", { userToken: req.userToken, referral: req.referral })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
};

export const updatePlan = async (req: {
  listings: Array<object>;
  userToken: string;
  period: string;
}): Promise<object> => {
  return new Promise((resolve, __) => {
    client
      .post("/updatePlan", { listings: req.listings, userToken: req.userToken, period: req.period })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
};

export const getHostNotes = async (req: GetHostNotesRequest): Promise<GetHostNotesResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/hostnotes`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const updateHostNotes = async (req: UpdateHostNotesRequest): Promise<UpdateHostNotesResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/hostnotes`, req);
    resolve({});
  });
};

export const getEarlyCheckinUpsells = async (
  req: GetEarlyCheckinUpsellsRequest
): Promise<GetEarlyCheckinUpsellsResponse> => {
  return new Promise((resolve, reject) => {
    client
      .get(`/earlyCheckinUpsells`, { params: { userToken: req.userToken } })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const updateEarlyCheckinUpsells = async (
  req: UpdateEarlyCheckinUpsellsRequest
): Promise<UpdateEarlyCheckinUpsellsResponse> => {
  return new Promise((resolve, reject) => {
    client
      .post(`/earlyCheckinUpsells`, req)
      .then(() => {
        resolve({});
      })
      .catch(reject);
  });
};

export const getTasks = async (req: GetTasksRequest): Promise<GetTasksResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/tasks`, { params: { userToken: req.userToken, page: req.page } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const updateTasks = async (req: UpdateTasksRequest): Promise<UpdateTasksResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/tasks`, req);
    resolve({});
  });
};

export const updateTask = async (req: UpdateTaskRequest): Promise<UpdateTaskResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/updateTask`, req);
    resolve({});
  });
};

export const getInquiryWinbackSettings = async (
  req: GetInquiryWinbackSettingsRequest
): Promise<GetInquiryWinbackSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/inquiryWinbackSettings`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const updateInquiryWinbackSettings = async (
  req: UpdateInquiryWinbackSettingsRequest
): Promise<UpdateInquiryWinbackSettingsResponse> => {
  return new Promise((resolve, _) => {
    client.post(`/inquiryWinbackSettings`, req);
    resolve({});
  });
};

export const getCopilotThreads = async (req: GetCopilotThreadsRequest): Promise<GetCopilotThreadsResponse> => {
  return new Promise((resolve, _) => {
    client.get(`/copilot`, { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const askCopilot = async (req: AskCopilotRequest): Promise<AskCopilotResponse | null> => {
  return new Promise((resolve, _) => {
    client
      .post("/askCopilot", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const createCopilotThread = async (req: CreateCopilotThreadRequest): Promise<CopilotThread | null> => {
  return new Promise((resolve, _) => {
    client
      .post("/createCopilotThread", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const deleteCopilotThread = async (req: DeleteCopilotThreadRequest): Promise<null> => {
  return new Promise((resolve, _) => {
    client
      .post("/deleteCopilotThread", req)
      .then((res) => {
        resolve(null);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const updateStripePlan = async (req: UpdateStripePlanRequest): Promise<UpdateStripePlanResponse> => {
  return new Promise((resolve, _) => {
    client.post("/updateStripePlan", req).then((res) => {
      resolve(res.data);
    });
  });
};

export const settleConfirmation = async (req: SettleConfirmationRequest): Promise<null> => {
  return new Promise((resolve, _) => {
    client
      .post("/settleConfirmation", req)
      .then((res) => {
        resolve(null);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const teamMemberEmails = async (req: {
  userToken: string;
}): Promise<{ email: string; extension_installed_at: string | null }[]> => {
  return new Promise((resolve, _) => {
    client.get("/teamMemberEmails", { params: { userToken: req.userToken } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const setFirstTimeListingsReady = async (req: { userToken: string }): Promise<void> => {
  return new Promise((resolve, _) => {
    client.get(`/setFirstTimeListingsReady`, { params: { userToken: req.userToken } }).then(() => {
      resolve();
    });
  });
};

export const getReviewResponderSettings = async (req: {
  userToken: string;
}): Promise<GetReviewResponderSettingsResponse> => {
  return client.get(`/reviewResponderSettings`, { params: { userToken: req.userToken } }).then((res) => res.data);
};

export const setReviewResponderSettings = async (req: GetReviewResponderSettingsRequest): Promise<void> => {
  return client.post(`/reviewResponderSettings`, req);
};

export const getReviewRequestSettings = async (
  req: GetReviewRequestSettingsRequest
): Promise<GetReviewRequestSettingsResponse> => {
  try {
    // Get token from localStorage if not provided in request
    const token = req.userToken || localStorage.getItem("besty_user_token");
    if (!token) {
      throw new Error("No user token found");
    }

    const response = await client.get(`/getReviewRequestSettings`, {
      params: { userToken: token },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const defaultSettings = {
      enabled: false,
      hours_before_departure: 12,
      is_before: true,
      template: "We hope you enjoyed your stay! Please leave us a review if you can.",
      hostaway_listing_id: null,
      fetched: true,
    };
    return {
      reviewRequestSettings: response.data?.reviewRequestSettings || defaultSettings,
    };
  } catch (error) {
    console.error("Error fetching review request settings:", error);
    return {
      reviewRequestSettings: {
        enabled: false,
        hours_before_departure: 12,
        is_before: true,
        template: "We hope you enjoyed your stay! Please leave us a review if you can.",
        hostaway_listing_id: null,
        fetched: true,
        false_message_template: `I'm sorry you didn't enjoy your stay. We apologize for any inconvenience you suffered, and would like us to know we did our best to make your stay as enjoyable as possible. Please let us know if you have any questions or concerns. Thank you for your time and consideration.`,
        send_false_message: false,
      },
    };
  }
};

export const updateReviewRequestSettings = async (
  req: UpdateReviewRequestSettingsRequest
): Promise<{
  success: boolean;
  reviewRequestSettings: {
    enabled: boolean;
    hours_before_departure: number;
    template: string;
    is_before: boolean;
    hostaway_listing_id: null;
    userToken: string;
    send_false_message: boolean;
    false_message_template: string;
  };
}> => {
  try {
    // Get token from localStorage if not provided in request
    const token = req.userToken || localStorage.getItem("besty_user_token");
    if (!token) {
      throw new Error("No user token found");
    }

    const settings = {
      enabled: req.reviewRequestSettings.enabled,
      hours_before_departure: req.reviewRequestSettings.hours_before_departure,
      template: req.reviewRequestSettings.template,
      is_before: req.reviewRequestSettings.is_before,
      hostaway_listing_id: null, // Global setting for all listings
      userToken: token,
      send_false_message: req.reviewRequestSettings.send_false_message,
      false_message_template: req.reviewRequestSettings.false_message_template,
    };
    console.log("Sending settings to backend:", settings);
    const response = await client.post(
      `/setReviewRequestSettings`,
      settings // Keep the wrapper as backend expects it
    );
    if (response.data.success) {
      return { success: true, reviewRequestSettings: settings };
    } else {
      throw new Error(response.data.error || "Failed to update settings");
    }
  } catch (error) {
    console.error("Error updating review request settings:", error);
    throw error;
  }
};

export const getReviews = async (req: GetReviewsRequest): Promise<object> => {
  return client.get(`/reviews`, { params: req }).then((res) => res.data);
};

export const sendReviewReply = async (req: SendReviewReplyRequest): Promise<void> => {
  return client.post(`/sendReviewReply`, req);
};

export const getRetargetingSettings = async (req: { userToken: string }): Promise<object> => {
  return client.get(`/retargetingSettings`, { params: req }).then((res) => res.data);
};

export const setRetargetingSettings = async (req: {
  userToken: string;
  settings: RetargetingSettings;
}): Promise<void> => {
  return client.post(`/retargetingSettings`, req);
};
export const getAttributions = async ({
  userToken,
  offset = 0,
  limit = 10,
}: {
  userToken: string;
  offset?: number;
  limit?: number;
}): Promise<GetAttributionsResponse> => {
  const res = await client.get(`/attributions`, {
    params: {
      userToken,
      offset,
      limit,
    },
  });
  return res.data;
};

export const getAttributionTable = async (req: { userToken: string }): Promise<GetAttributionTableResponse> => {
  const res = await client.get(`/attributionStats`, {
    params: {
      userToken: req.userToken,
    },
  });
  return res.data;
};

export const rejectAttribution = async (req: RejectAttributionRequest): Promise<void> => {
  return new Promise((resolve, _) => {
    client
      .delete(`/attributions`, { params: { userToken: req.userToken, attributionTableId: req.attributionTableId } })
      .then((res) => {
        resolve();
      });
  });
};

export const getBillingSettings = async (req: GetBillingSettingsRequest): Promise<GetBillingSettingsResponse> => {
  return client.get(`/billingSettings`, { params: req }).then((res) => res.data);
};

export const updateBillingSettings = async (
  req: UpdateBillingSettingsRequest
): Promise<UpdateBillingSettingsResponse> => {
  return client.post(`/billingSettings`, req).then((res) => res.data);
};

export const getAgentStats = async (req: GetAgentStatsRequest): Promise<GetAgentStatsResponse> => {
  return client.get(`/agentStats`, { params: req }).then((res) => res.data);
};

export const syncListings = async (req: { userToken: string }): Promise<void> => {
  return client.post(`/syncListings`, req).then(() => {});
};
export const fetchUserById = async (req: { userId: string }): Promise<any> => {
  return new Promise((resolve, reject) => {
    client
      .get(`fetchUsers`, { params: { userId: req.userId } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const fetchUsers = async (req: FetchUsersRequest): Promise<FetchUsersResponse> => {
  return new Promise((resolve, reject) => {
    client
      .get(`/users`, { params: { userToken: req.userToken } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReviewTasks = async (req: { userToken: string }): Promise<GetTasksResponse> => {
  return new Promise((resolve, reject) => {
    console.log("User token:", req.userToken); // Log user token
    client
      .get(`/reviewTasks`, { params: { userToken: req.userToken } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export type SetReviewReadRequest = {
  userId: string;
  setRead: boolean;
  review_id: string;
};

export const setReviewRead = async (
  req: SetReviewReadRequest
): Promise<{ success: boolean; message: string } | null> => {
  return new Promise((resolve, reject) => {
    client
      .get(`setReviewRead`, {
        params: {
          userId: req.userId,
          setRead: req.setRead,
          review_id: req.review_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error in setReviewRead:", error);
        reject(error);
      });
  });
};

export const createHostawayTask = async (req: {
  userToken: string;
  title: string;
  description?: string;
  assigneeId?: string;
}): Promise<{ message: string; taskId: string } | null> => {
  return new Promise((resolve, _) => {
    client
      .post("/createHostawayTask", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error creating Hostaway task:", err);
        resolve(null);
      });
  });
};

export const createGuestyTask = async (req: {
  userToken: string;
  title: string;
  description?: string;
  assigneeId?: string;
}): Promise<{ message: string; taskId: string } | null> => {
  return new Promise((resolve, _) => {
    client
      .post("/createGuestyTask", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error creating Guesty task:", err);
        resolve(null);
      });
  });
};

export const setResolved = async (req: {
  userToken: string;
  reservationId: string;
  resolved: boolean;
}): Promise<{ success: boolean; message: string } | null> => {
  return new Promise((resolve, _) => {
    client
      .post("/setResolved", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error setting resolved:", err);
        resolve(null);
      });
  });
};

export const getTimeUpdates = async (req: {
  userToken: string;
  resolved: boolean;
}): Promise<{ success: boolean; TimeUpdates: TimeUpdatesType[] } | null> => {
  return new Promise((resolve) => {
    client
      .get("/timeUpdates", { params: { userToken: req.userToken, resolved: req.resolved } })
      .then((res) => {
        resolve({ success: true, TimeUpdates: res.data });
      })
      .catch((error) => {
        console.error("Error fetching time updates:", error);
        resolve(null);
      });
  });
};

export const hideTimeUpdates = async (req: {
  userToken: string;
  reservationId: string;
  pms: string;
  resolved: boolean;
}): Promise<{ success: boolean } | null> => {
  return new Promise((resolve) => {
    client
      .put("/hideTimeUpdate", {
        body: { reservation_id: req.reservationId, pms: req.pms, resolved: req.resolved },
      })
      .then((res) => {
        resolve({ success: true });
      })
      .catch((error) => {
        console.error("Error fetching time updates:", error);
        resolve(null);
      });
  });
};

export const updateTimeUpdateItem = async (req: {
  user_id: string;
  reservation_id: string;
  new_time: string;
  isCheckinUpdate: boolean;
}): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    client
      .put("/updateTimeUpdateItem", {
        body: {
          user: req.user_id,
          reservation_id: req.reservation_id,
          new_time: req.new_time,
          isCheckinUpdate: req.isCheckinUpdate,
        },
      })
      .then((res) => {
        resolve({ success: true });
      })
      .catch((error) => {
        console.error("Error fetching time updates:", error);
      });
  });
};

export const sendTimeUpdateToPms = async (req: {
  user_id: string;
  reservation_id: string;
  new_time: string;
  isCheckinUpdate: boolean;
}): Promise<any> => {
  return new Promise((resolve) => {
    client
      .post("/sendTimeUpdateToPms", {
        user_id: req.user_id,
        reservation_id: req.reservation_id,
        new_time: req.new_time,
        isCheckinUpdate: req.isCheckinUpdate,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error fetching time updates:", error);
      });
  });
};

export const getAllJourneysForUser = async (req: { user_id: string }): Promise<any> => {
  return new Promise((resolve) => {
    client.get("/getJourneysForUser", { params: { ...req } }).then((res) => {
      resolve(res.data);
    });
  });
};

export const saveJourneysForUser = async (req: { user_id: string; journeys: MessageJourney[] }): Promise<any> => {
  return new Promise((resolve) => {
    client
      .post("/saveJourneysForUser", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error fetching time updates:", error);
      });
  });
};

export const deleteJourney = async (req: { user_token: string; journey_id: number }): Promise<any> => {
  return new Promise((resolve) => {
    client
      .post("/deleteJourney", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error deleting journey:", error);
      });
  });
};

export const submitDataCollection = async (req: { user_token: string; data_form: object }) => {
  return new Promise((resolve, reject) => {
    client
      .post("/submitDataCollection", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const isDataFormFilledOut = async (req: { user_token: string }) => {
  return new Promise((resolve, reject) => {
    client
      .get("/isDataFormFilledOut", {
        params: { user_token: req.user_token },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchListingTemplateData = async (req: {
  user_token: string;
  listing_id: string;
}): Promise<BtTemplateVarResponseType> => {
  return new Promise((resolve, reject) => {
    client
      .get("/bt_template_vars", { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateListingTemplateData = async (req: {
  user_token: string;
  listing_id: string;
  data_list: BtTemplateVarType[];
}) => {
  return new Promise((resolve, reject) => {
    client
      .post("/save_bt_template_vars", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteListingTemplateVariables = async (req: {
  user_token: string;
  listing_id: string;
  template_name: string;
}): Promise<object> => {
  return new Promise((resolve, reject) => {
    client
      .post("/delete_bt_listing_template_vars", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveListingSiteURL = async (req: {
  listing_id: string;
  url: string;
  user_token: string;
}): Promise<object> => {
  return new Promise((resolve, reject) => {
    client
      .post("/save_direct_booking_url", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// STRIPE CONNECTED ACCOUNTS
export const createConnectedStripeAccount = async (req: {
  userToken: string;
}): Promise<{ connectedAccountId: string }> => {
  return client.post(`/createConnectedStripeAccount`, req);
};

export const accountLink = async (req: {
  userToken: string;
  accountId: string;
  needNewLink?: boolean;
}): Promise<{ url: string }> => {
  return client.post(`/accountLink`, req);
};

export const checkOnboardingCompletion = async (req: {
  userToken: string;
  accountId: string;
}): Promise<{ enabled: boolean }> => {
  return client.get(`/checkOnboardingCompletion`, { params: req });
};

export const getAllTemplateVars = async (req: {
  userToken: string;
}): Promise<{
  result: TemplateVariable[];
}> => {
  return new Promise((resolve, reject) => {
    client
      .get("/getAllTemplateVariablesForUser", { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplateVarString = async (req: { userToken: string }): Promise<{ template_string: string }> => {
  return new Promise((resolve, reject) => {
    client
      .get("/get_template_var_string", { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const callSaveTemplateVarString = async (req: { user_token: string; template_string: string }): Promise<any> => {
  return new Promise((resolve, reject) => {
    client
      .post("/save_template_var_string", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error calling /api/save_template_var_string:", error);
        reject(error);
      });
  });
};

export const writeWhatsappPhoneData = async (req: {
  userToken: string;
  whatsapp_biz_id: string;
  whatsapp_phone_id: string;
}) => {
  return new Promise((resolve, reject) => {
    client
      .post("/writeWhatsappPhoneData", req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error calling /api/save_template_var_string:", error);
        reject(error);
      });
  });
};

export const isWhatsappDataWritten = async (req: { userToken: string }): Promise<{ dataExists: boolean }> => {
  return new Promise((resolve, reject) => {
    client
      .get("/isWhatsappDataWritten", { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error calling /api/isWhatsappDataWritten:", error);
        reject(error);
      });
  });
};

// returns either pending_twilio_registration or fill_out_form
export const getTwilioStatus = async (req: { userToken: string }): Promise<{ status: string }> => {
  return new Promise((resolve, reject) => {
    client
      .get("/getTwilioStatus", { params: req })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error calling /api/getTwilioStatus:", error);
        reject(error);
      });
  });
};
